<template>
    <v-row justify="center" class="py-5" align="center" style="height: 100%">
        <v-col cols="12" sm="6">
            <!-- <v-row justify="center" no-gutters>
            <v-avatar tile size="48" class="my-6">
                <BrandImage :alias="brand.alias" intent="logomark" mode="light" etag="0" :width="48" :height="48" :max-height="48" :max-width="48" contain style="margin-left: auto; margin-right: auto;"/>
            </v-avatar>
            </v-row> -->
            <BrandImage :alias="brand.alias" intent="logotype" mode="light" etag="0" :height="56" :max-height="56" contain style="margin-left: auto; margin-right: auto;" class="mb-6"/>
            <BrandCard>
                <v-card-text>
                    <!-- <router-link :to="{ name: 'brand-prefeerences', params: { alias: this.$route.params.alias } }">Email Preferences</router-link>
                    | -->
                    <p>
                        We make it easy to opt in or out of email communications.
                    </p>
                    <p class="mb-0">
                    <router-link :to="{ name: 'brand-subscribe', params: { alias: this.$route.params.alias }, query: { email: this.$route.query.email } }">Subscribe</router-link>
                    |
                    <router-link :to="{ name: 'brand-unsubscribe', params: { alias: this.$route.params.alias }, query: { email: this.$route.query.email } }">Unsubscribe</router-link>
                    </p>
                </v-card-text>
            </BrandCard>
        </v-col>
    </v-row>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
</style>

<script>
import { mapState } from 'vuex';
import BrandCard from '@/components/BrandCard.vue';
import BrandImage from '@/components/BrandImage.vue';

export default {
    components: {
        BrandCard,
        BrandImage,
    },
    data: () => ({
    }),
    computed: {
        ...mapState({
            brand: (state) => state.brand,
            palette: (state) => state.palette,
        }),
        mainWebsiteURL() {
            return process.env.VUE_APP_MAIN_WEBSITE_URL ?? 'https://emplus.io';
        },
        // brandprofileSearchURL() {
        //     return process.env.VUE_APP_BRANDPROFILE_SEARCH_URL ?? 'https://cdn.brandprofile.org/search';
        // },
    },
};
</script>
